import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Uptime = () => {
    const [uptimeData, setUptimeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const url = 'http://51.81.228.214:1337/server-uptime';

    const fetchUptimeData = async () => {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch from ${url} with status ${response.status}`);
            }
            const data = await response.json();
            toast.success('Fetched server uptime data successfully');
            setUptimeData(data.uptimes || []); // Assuming the backend sends an object with a "uptimes" array
        } catch (error) {
            toast.error(`Error fetching data: ${error.message}`);
            console.error(`Error fetching data:`, error.message);
            setError('Failed to fetch server uptime.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUptimeData();
    }, []);

    const getServerFlagUrl = (serverName) => {
        switch (serverName) {
            case 'Oregon':
                return 'https://cdn.countryflags.com/thumbs/united-states-of-america/flag-round-250.png';
            case 'London':
                return 'https://cdn.countryflags.com/thumbs/united-kingdom/flag-round-250.png';
            case 'Virginia':
                return 'https://cdn.countryflags.com/thumbs/united-states-of-america/flag-round-250.png';
            default:
                return 'https://i.pinimg.com/736x/38/47/9c/38479c637a4ef9c5ced95ca66ffa2f41.jpg';
        }
    };

    return (
        <div className='bg-none text-neutral-200 m-4 p-8 rounded-xl shadow-2xl relative'>
            <ToastContainer />
            <h2 className='text-xl font-semibold text-neutral-200 mb-4'>Server Uptime</h2>
            {error && <p className='text-red-500'>{error}</p>}
            {loading ? (
                <div className="flex justify-center items-center">
                    <span className="loading loading-infinity loading-lg"></span>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Server Name</th>
                                <th>Uptime</th>
                                <th>CPU Usage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {uptimeData.map((uptime, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="flex items-center gap-3">
                                            <div className="avatar">
                                                <div className="rounded-lg h-12 w-12">
                                                    <img
                                                        src={getServerFlagUrl(uptime.name) || 'https://files.catbox.moe/4fw33q.png'}
                                                        alt={`${uptime.name} Avatar`}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="font-bold">{uptime.name}</div>
                                                <div className="text-sm opacity-50">{uptime.location}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{uptime.uptime}</td>
                                    <td>{uptime.cpuUsage}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Uptime;
