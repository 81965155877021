import React from 'react';
import { useTheme } from '../context/ThemeContext';

const Settings = () => {
    const { changeTheme, theme } = useTheme();

    return (
        <div className='flex justify-center items-center mt-20'>
            <div className="card card-side bg-base-100 shadow-xl w-fit p-5">
                <select
                    className="select select-secondary w-full max-w-xs"
                    onChange={(e) => changeTheme(e.target.value)}
                    value={theme} // Set the current value of the select to the active theme
                >
                    <option disabled>Change the website theme</option>
                    <option value="light">Light</option>
                    <option value="dark">Dark</option>
                    <option value="cupcake">Cupcake</option>
                    <option value="bumblebee">Bumblebee</option>
                    <option value="emerald">Emerald</option>
                    <option value="corporate">Corporate</option>
                    <option value="synthwave">Synthwave</option>
                    <option value="retro">Retro</option>
                    <option value="cyberpunk">Cyberpunk</option>
                    <option value="valentine">Valentine</option>
                    <option value="halloween">Halloween</option>
                    <option value="garden">Garden</option>
                    <option value="forest">Forest</option>
                    <option value="aqua">Aqua</option>
                    <option value="lofi">Lo-Fi</option>
                    <option value="pastel">Pastel</option>
                    <option value="fantasy">Fantasy</option>
                    <option value="wireframe">Wireframe</option>
                    <option value="black">Black</option>
                    <option value="luxury">Luxury</option>
                    <option value="dracula">Dracula</option>
                    <option value="cmyk">CMYK</option>
                </select>
            </div>
        </div>
    );
};

export default Settings;
