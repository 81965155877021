import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';

const AdminRoute = ({ children }) => {
    const { currentUser } = useAuth();

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    if (!currentUser.admin) {
        return <Navigate to="/" />;
    }

    return children;
};

export default AdminRoute;
