import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersCollection = collection(db, 'users');
                const usersSnapshot = await getDocs(usersCollection);
                const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsers(usersList);
            } catch (error) {
                toast.error('Error fetching users.');
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false); // Set loading to false once data is fetched
            }
        };

        fetchUsers();
    }, []);

    const handleAdminToggle = async (userId, currentStatus) => {
        const userDocRef = doc(db, 'users', userId);
        try {
            await updateDoc(userDocRef, { admin: !currentStatus });
            setUsers(users.map(user =>
                user.id === userId ? { ...user, admin: !currentStatus } : user
            ));
            toast.success('Admin status updated successfully!');
        } catch (error) {
            toast.error('Error updating admin status. Please try again.');
            console.error('Error updating admin status:', error);
        }
    };

    return (
        <div className="w-full">
            <ToastContainer />
            {loading ? (
                <div className="flex justify-center items-center min-h-screen">
                    <span className="loading loading-infinity loading-lg"></span>
                </div>
            ) : (
                <div className="overflow-x-auto w-full min-w-full">
                    <table className="table w-full">
                        <thead>
                            <tr>
                                <th></th>
                                <th>User Info</th>
                                <th>Admin</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.id}>
                                    <th>
                                        <label>
                                            <input type="checkbox" className="checkbox" />
                                        </label>
                                    </th>
                                    <td>
                                        <div className="flex items-center gap-3">
                                            <div className="avatar">
                                                <div className="mask mask-squircle h-12 rounded-full w-12">
                                                    <img
                                                        src={user.profilePicture || "https://i.pinimg.com/736x/38/47/9c/38479c637a4ef9c5ced95ca66ffa2f41.jpg"}
                                                        alt="User Avatar"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="font-bold">{user.username}</div>
                                                <div className="text-sm opacity-50">{user.email}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            className="toggle toggle-primary"
                                            checked={user.admin}
                                            onChange={() => handleAdminToggle(user.id, user.admin)}
                                        />
                                    </td>
                                    <td>
                                        <button className="btn btn-ghost btn-xs">Details</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Users;
