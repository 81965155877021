import React from 'react';

const AdminSidebar = ({ setActiveTab }) => {
    return (
        <div className="drawer lg:drawer-open w-0">
            <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
            <div className="drawer-side fixed top-0 left-0 h-full">
                <label htmlFor="my-drawer-2" aria-label="close sidebar" className="drawer-overlay"></label>
                <ul className="menu bg-base-200 text-base-content min-h-full w-80 p-4">
                    <li><button onClick={() => setActiveTab('dashboard')}>Dashboard</button></li>
                    <li><button onClick={() => setActiveTab('users')}>Users</button></li>
                    <li><button onClick={() => setActiveTab('products')}>Products</button></li>
                </ul>
            </div>
            <div className="drawer-content flex flex-col items-center justify-center lg:hidden">
                <label htmlFor="my-drawer-2" className="btn btn-primary drawer-button">
                    Open Sidebar
                </label>
            </div>
        </div>
    );
};

export default AdminSidebar;