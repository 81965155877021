import React, { useState } from 'react';
import { useAuth } from '../context/AuthProvider';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

const Navbar = () => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login'); // Redirect to the login page after logout
        } catch (error) {
            console.error("Logout failed", error);
        }
    };

    return (
        <div className="navbar bg-base-100">
            <div className="navbar-start">
                <div className="dropdown">
                    <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h8m-8 6h16" />
                        </svg>
                    </div>
                    <ul
                        tabIndex={0}
                        className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                        <li><a href='/products'>Products</a></li>
                        <li><a href='/uptime'>Uptime</a></li>
                        <li><a href='/about'>About</a></li>
                        <li><a href='/support'>Support</a></li>
                        <li><a href='/terms'>Terms</a></li>
                    </ul>
                </div>
                <a href='/' className="btn btn-ghost text-xl">Privsea Networks</a>
            </div>
            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal px-1">
                    <li><a href='/products'>Products</a></li>
                    <li><a href='/uptime'>Uptime</a></li>
                    <li><a href='/about'>About</a></li>
                    <li><a href='/support'>Support</a></li>
                    <li><a href='/terms'>Terms</a></li>
                </ul>
            </div>
            <div className="navbar-end">
                {currentUser ? (
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                            <div className="w-10 rounded-full">
                                <img
                                    alt="profile picture"
                                    src={currentUser.profilePicture || 'https://i.pinimg.com/736x/38/47/9c/38479c637a4ef9c5ced95ca66ffa2f41.jpg'} />
                            </div>
                        </div>
                        <ul
                            tabIndex={0}
                            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                            <li><a href='/dashboard' className="justify-between">Profile</a></li>
                            {currentUser.admin && (
                                <li>
                                    <a href='/admin'>Admin Panel
                                        <span className="badge">New</span>
                                    </a>
                                </li>
                            )}
                            <li><a href='/settings'>Settings</a></li>
                            <li><button onClick={handleLogout}>Logout</button></li>
                        </ul>
                    </div>
                ) : (
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                            <div className="w-10 rounded-full">
                                <img
                                    alt="default profile picture"
                                    src="https://i.pinimg.com/736x/38/47/9c/38479c637a4ef9c5ced95ca66ffa2f41.jpg" />
                            </div>
                        </div>
                        <ul
                            tabIndex={0}
                            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                            <li><a href="/login">Sign In</a></li>
                            <li><a href='/settings'>Settings</a></li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Navbar;
