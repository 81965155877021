import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../context/AuthProvider';
import { toast } from 'react-toastify';

const Services = () => {
    const [servers, setServers] = useState([]);
    const [plans, setPlans] = useState({});
    const [selectedPlan, setSelectedPlan] = useState(null); // Track the plan selected for termination
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchServers = async () => {
            try {
                const serversCollection = collection(db, 'servers');
                const serversSnapshot = await getDocs(serversCollection);
                const serversList = serversSnapshot.docs.map(doc => doc.data());
                setServers(serversList);
                toast.success('Servers fetched successfully!');
            } catch (error) {
                toast.error('Error fetching servers');
                console.error('Error fetching servers:', error);
            }
        };

        const fetchUserPlans = async () => {
            try {
                if (currentUser) {
                    const usersCollection = collection(db, 'users');
                    const userQuery = query(usersCollection, where('email', '==', currentUser.email));
                    const userSnapshot = await getDocs(userQuery);
                    if (!userSnapshot.empty) {
                        const userData = userSnapshot.docs[0].data();
                        setPlans(userData.plans || {});
                        toast.success('Plans fetched successfully!');
                    } else {
                        setPlans({});
                        toast.error('No plans found for this user.');
                    }
                }
            } catch (error) {
                toast.error('Error fetching user plans');
                console.error('Error fetching user plans:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchServers();
        fetchUserPlans();
    }, [currentUser]);

    const handleTerminate = (planId) => {
        setSelectedPlan(planId);
        document.getElementById('my_modal_3').showModal();
    };

    const confirmTerminate = async () => {
        if (currentUser && selectedPlan) {
            try {
                const userDoc = doc(db, 'users', currentUser.uid);
                const updatedPlans = { ...plans };
                delete updatedPlans[selectedPlan];

                await updateDoc(userDoc, { plans: updatedPlans });
                setPlans(updatedPlans);
                toast.success('Plan terminated successfully!');
            } catch (error) {
                toast.error('Error terminating plan');
                console.error('Error terminating plan:', error);
            } finally {
                document.getElementById('my_modal_3').close();
            }
        }
    };

    if (loading) {
        return (
            <div className='flex justify-center items-center h-1/3'>
                <span className="loading loading-infinity loading-lg"></span>
            </div>
        );
    }

    return (
        <div>
            <div className="overflow-x-auto">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Server Info</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Duration</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(plans).length > 0 ? (
                            Object.keys(plans).map((planId, index) => {
                                const plan = plans[planId];
                                const server = servers.find(s => s.serverId === planId);
                                return server ? (
                                    <tr key={index}>
                                        <td>
                                            <div className="flex items-center gap-3">
                                                <div className="avatar">
                                                    <div className="mask mask-squircle h-12 w-12">
                                                        <img
                                                            src={server.FlagUrl || "https://files.catbox.moe/4fw33q.png"}
                                                            alt={plan.serverName} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="font-bold">{plan.serverName || server.serverName}</div>
                                                    <div className="text-sm opacity-50">{server.location || "Unknown"}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{server.type || "N/A"}</td>
                                        <td>{plan.status ? "Active" : "Inactive"}</td>
                                        <td>{plan.duration}</td>
                                        <th>
                                            <button onClick={() => handleTerminate(planId)} className="btn btn-ghost btn-xs">Terminate Plan</button>
                                            <dialog id="my_modal_3" className="modal">
                                                <div className="modal-box">
                                                    <form method="dialog">
                                                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                                    </form>
                                                    <h3 className="font-bold text-lg">Are you sure you want to terminate your plan?</h3>
                                                    <p className="py-4 mb-10">Terminating your plan will result in immediate loss of access to your server. Once confirmed, you will not receive a refund and will no longer be able to access the server.</p>
                                                    <button onClick={confirmTerminate} className="btn btn-error">Terminate Plan</button>
                                                </div>
                                            </dialog>
                                        </th>
                                    </tr>
                                ) : (
                                    <tr key={index}>
                                        <td colSpan="5">Server information not found for plan {plan.serverName}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="5">No plans available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div >
    );
};

export default Services;
