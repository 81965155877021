import React, { useState } from 'react';
import AdminSidebar from '../components/AdminSidebar';
import Dashboard from '../components/Dashboard';
import Users from '../components/Users';
import Products from '../components/Products';

const Admin = () => {
    const [activeTab, setActiveTab] = useState('dashboard');

    const renderContent = () => {
        switch (activeTab) {
            case 'dashboard':
                return <Dashboard />;
            case 'users':
                return <Users />;
            case 'products':
                return <Products />;
            default:
                return <Dashboard />;
        }
    };

    return (
        <div className="flex min-h-screen">
            <AdminSidebar setActiveTab={setActiveTab} />
            <div className="flex-grow flex justify-center p-4 ml-80">
                <div className="max-w-4xl w-full">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

export default Admin;
