import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import { doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { PiEye, PiEyeClosedBold } from 'react-icons/pi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

const Setup = () => {
    const { currentUser } = useAuth();
    const [username, setUsername] = useState(currentUser?.username || '');
    const [email, setEmail] = useState(currentUser?.email || '');
    const [phoneNumber, setPhoneNumber] = useState(currentUser?.phoneNumber || '');
    const [profilePicture, setProfilePicture] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasUppercase: false,
    });
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const navigate = useNavigate();  // Initialize navigate

    useEffect(() => {
        const lengthValid = password.length >= 8;
        const numberValid = /\d/.test(password);
        const specialCharValid = /[!@#$%^&*]/.test(password);
        const uppercaseValid = /[A-Z]/.test(password);
        const passwordsAreEqual = password === confirmPassword;

        setPasswordValidations({
            length: lengthValid,
            hasNumber: numberValid,
            hasSpecialChar: specialCharValid,
            hasUppercase: uppercaseValid,
        });

        setPasswordsMatch(passwordsAreEqual);

        if (lengthValid && numberValid && specialCharValid && uppercaseValid && passwordsAreEqual) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [password, confirmPassword]);

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        if (name === 'password') {
            setPassword(value);
            setPasswordsMatch(value === confirmPassword);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
            setPasswordsMatch(password === value);
        }
    };

    const handleProfilePictureUpload = async () => {
        if (!profilePicture) return '';

        const storageRef = ref(storage, `profilePictures/${currentUser.uid}_${profilePicture.name}`);
        await uploadBytes(storageRef, profilePicture);
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    };

    const handleSave = async () => {
        try {
            const userRef = doc(db, 'users', currentUser.uid);

            let profilePictureURL = currentUser.profilePicture;
            if (profilePicture) {
                profilePictureURL = await handleProfilePictureUpload();
            }

            await updateDoc(userRef, {
                username,
                email,
                phoneNumber,
                profilePicture: profilePictureURL,
                completed: true,
            });

            if (password && passwordsMatch) {
                // Handle password update if necessary
                // Example: await currentUser.updatePassword(password);
                // This may require re-authentication
            }

            toast.success('Information updated successfully!');
            navigate('/dashboard');  // Navigate to /dashboard after successful save
        } catch (error) {
            toast.error('Failed to update information. Please try again.');
        }
    };

    return (
        <div className="flex justify-center flex-col items-center">
            <ToastContainer />
            <div className="card bg-base-100 w-full max-w-sm shadow-2xl">
                <div className="card-body">
                    <h3 className="text-lg font-bold mb-4">Finish setting up your account</h3>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Username</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Enter your username"
                            className="input input-bordered"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Email</span>
                        </label>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="input input-bordered"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Phone Number</span>
                        </label>
                        <input
                            type="tel"
                            placeholder="Enter your phone number"
                            className="input input-bordered"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Profile Picture</span>
                        </label>
                        <input
                            type="file"
                            className="file-input file-input-bordered w-full max-w-xs"
                            onChange={(e) => setProfilePicture(e.target.files[0])}
                            accept="image/*"
                        />
                    </div>
                    <div className="form-control relative">
                        <label className="label">
                            <span className="label-text">Password</span>
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            className="input input-bordered pr-10"
                            name="password"
                            value={password}
                            onFocus={() => setIsPasswordFocused(true)}
                            onBlur={() => setIsPasswordFocused(false)}
                            onChange={handlePasswordChange}
                        />
                        <span
                            className="absolute inset-y-2 top-1/2 right-0 pr-3 flex items-center justify-center cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <PiEyeClosedBold size={24} /> : <PiEye size={24} />}
                        </span>
                    </div>
                    <div className="form-control relative">
                        <label className="label flex items-center">
                            <span className="label-text">Confirm Password</span>
                            <span className={`ml-2 text-sm ${passwordsMatch ? 'text-green-500' : 'text-red-500'}`}>
                                {passwordsMatch ? '✔ Passwords match' : '✖ Passwords don\'t match'}
                            </span>
                        </label>
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm your password"
                            className="input input-bordered pr-10"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handlePasswordChange}
                        />
                        <span
                            className="absolute inset-y-2 top-1/2 right-0 pr-3 flex items-center cursor-pointer"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            {showConfirmPassword ? <PiEyeClosedBold size={24} /> : <PiEye size={24} />}
                        </span>
                    </div>
                    {isPasswordFocused && (
                        <div className="mt-2">
                            <ul className="text-sm">
                                <li className={`flex items-center ${passwordValidations.length ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.length ? '✔' : '✖'} At least 8 characters
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasNumber ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasNumber ? '✔' : '✖'} Contains a number
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasSpecialChar ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasSpecialChar ? '✔' : '✖'} Contains a special character
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasUppercase ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasUppercase ? '✔' : '✖'} Contains an uppercase letter
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="form-control mt-6">
                        <button
                            className="btn btn-primary w-full"
                            onClick={handleSave}
                            disabled={isButtonDisabled}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Setup;
