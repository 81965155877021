import React from 'react'
import { FaDiscord } from 'react-icons/fa'

const handleDiscord = (user) => {
    window.open(`https://discord.com/users/${user}`)
}

const About = () => {
    return (
        <div className='flex flex-col items-center mt-20'>
            <div>
                <h1 className="text-4xl font-bold mb-20">About Us</h1>
            </div>
            <div className="flex w-[40%] flex-col lg:flex-row">
                <div className="card bg-base-300 rounded-box grid h-fit flex-grow place-items-center">
                    <div className='flex flex-col justify-center items-center relative'>
                        <img src="https://cdn.discordapp.com/avatars/865479837422321664/62b0752cb5a0042594493f68dbdff360.webp?size=160" alt="tezzo" className="rounded-full w-32 h-32 -translate-y-16" />
                    </div>
                    <div className='-translate-y-14 flex flex-col items-center'>
                        <h1 className="text-2xl font-bold">Tezzo</h1>
                        <p className="text-lg">Server Manager</p>
                        <button className="btn btn-secondary mt-6 px-10 text-white hover:scale-105 hover:-translate-y-2" onClick={() => { handleDiscord("865479837422321664") }}>
                            <FaDiscord className="mr-2" size={24} />
                            <span>Discord</span>
                        </button>
                    </div>

                </div>
                <div className="divider divider-primary lg:divider-horizontal"></div>
                <div className="card bg-base-300 rounded-box grid h-fit flex-grow place-items-center">
                    <div className='flex flex-col justify-center items-center relative'>
                        <img src="https://cdn.discordapp.com/avatars/1001362015094317138/2fb4019ba409b20f25fd90125d996223.webp?size=160" alt="seracyris" className="rounded-full w-32 h-32 -translate-y-16" />
                    </div>
                    <div className='-translate-y-14 flex flex-col items-center'>
                        <h1 className="text-2xl font-bold">Cyris</h1>
                        <p className="text-lg">Web Developer</p>
                        <button className="btn btn-secondary mt-6 px-10 text-white hover:scale-105 hover:-translate-y-2" onClick={() => { handleDiscord("1001362015094317138") }}>
                            <FaDiscord className="mr-2" size={24} />
                            <span>Discord</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About