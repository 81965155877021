import React from 'react';

const Terms = () => {
    return (
        <div className='flex flex-col justify-center items-center'>
            <h1 className='text-3xl font-bold mt-10'>Terms and Conditions</h1>
            <p className='text-lg mt-5'>At Privsea, we are committed to providing a secure and private online experience. By using our VPN services, you agree to the following terms and conditions.</p>
            <div className="join join-vertical w-fit mt-5">
                <div className="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="my-accordion-4" defaultChecked />
                    <div className="collapse-title text-xl font-medium">Privacy and Data Security</div>
                    <div className="collapse-content">
                        <p>We are committed to protecting your privacy. We do not log your browsing activity or share your personal data with third parties. All data is encrypted and remains confidential while using our VPN services.</p>
                    </div>
                </div>
                <div className="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="my-accordion-4" />
                    <div className="collapse-title text-xl font-medium">Prohibited Activities</div>
                    <div className="collapse-content">
                        <p>Our VPN service is not to be used for illegal activities, including but not limited to hacking, fraud, or distributing malicious software. Any violations may result in immediate account termination without a refund.</p>
                    </div>
                </div>
                <div className="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="my-accordion-4" />
                    <div className="collapse-title text-xl font-medium">Account Management</div>
                    <div className="collapse-content">
                        <p>You are responsible for maintaining the confidentiality of your account credentials. If you lose access to your account, we may not be able to recover it, and you will need to create a new one.</p>
                    </div>
                </div>
                <div className="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="my-accordion-4" />
                    <div className="collapse-title text-xl font-medium">Service Availability</div>
                    <div className="collapse-content">
                        <p>We strive to provide uninterrupted VPN service, but there may be times when the service is unavailable due to maintenance or technical issues. We will do our best to minimize such interruptions.</p>
                    </div>
                </div>
                <div className="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="my-accordion-4" />
                    <div className="collapse-title text-xl font-medium">Subscription and Payments</div>
                    <div className="collapse-content">
                        <p>Your subscription to our VPN service is billed in advance. Payments are non-refundable, and we recommend reviewing our service details before making a purchase.</p>
                    </div>
                </div>
                <div className="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="my-accordion-4" />
                    <div className="collapse-title text-xl font-medium">Account Termination</div>
                    <div className="collapse-content">
                        <p>You may terminate your account at any time. Termination of your account will result in the loss of access to our VPN services, and no refunds will be issued for any remaining subscription period.</p>
                    </div>
                </div>
                <div className="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="my-accordion-4" />
                    <div className="collapse-title text-xl font-medium">Refund Policy</div>
                    <div className="collapse-content">
                        <p>We do not offer refunds once a payment has been processed. Please ensure that our VPN service meets your needs before making a purchase.</p>
                    </div>
                </div>
                <div className="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="my-accordion-4" />
                    <div className="collapse-title text-xl font-medium">Changes to Terms</div>
                    <div className="collapse-content">
                        <p>We may update our terms of service from time to time. We will notify you of any changes by posting the new terms on our website. Your continued use of our VPN services constitutes acceptance of the new terms.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Terms;
