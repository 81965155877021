import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';

const Dashboard = () => {
    const [activeTab, setActiveTab] = useState('Sales'); // Default active tab
    const [salesData, setSalesData] = useState({ totalSales: 0, totalRevenue: 0, totalOrders: 0 });
    const [userData, setUserData] = useState({ totalUsers: 0, activeUsers: 0, inactiveUsers: 0 });
    const [serverData, setServerData] = useState({ totalServers: 0, activeServers: 0, inactiveServers: 0 });

    useEffect(() => {
        const fetchData = async () => {
            // Fetch revenue document
            const revenueDocRef = doc(db, 'data', 'revenue');
            const revenueDoc = await getDoc(revenueDocRef);
            const revenueData = revenueDoc.data();

            // Fetch orders document
            const ordersDocRef = doc(db, 'data', 'orders');
            const ordersDoc = await getDoc(ordersDocRef);
            const ordersData = ordersDoc.data();

            setSalesData({
                totalSales: ordersData?.totalSales || 0,
                totalRevenue: revenueData?.amount || 0,
                totalOrders: ordersData?.totalOrders || 0,
            });

            // Fetch user data
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const users = usersSnapshot.docs.map(doc => doc.data());
            const activeUsers = users.filter(user => user.isActive).length;
            const inactiveUsers = users.length - activeUsers;

            setUserData({
                totalUsers: users.length,
                activeUsers,
                inactiveUsers,
            });

            // Fetch server data
            const serversSnapshot = await getDocs(collection(db, 'servers'));
            const servers = serversSnapshot.docs.map(doc => doc.data());
            const activeServers = servers.filter(server => server.isActive).length;
            const inactiveServers = servers.length - activeServers;

            setServerData({
                totalServers: servers.length,
                activeServers,
                inactiveServers,
            });
        };

        fetchData();
    }, []);

    const renderContent = () => {
        switch (activeTab) {
            case 'Sales':
                return (
                    <div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                            <div className="card bordered">
                                <div className="card-body">
                                    <h2 className="card-title">Total Sales</h2>
                                    <p className="text-3xl font-semibold">{salesData.totalSales}</p>
                                </div>
                            </div>
                            <div className="card bordered">
                                <div className="card-body">
                                    <h2 className="card-title">Total Revenue</h2>
                                    <p className="text-3xl font-semibold">${salesData.totalRevenue}</p>
                                </div>
                            </div>
                            <div className="card bordered">
                                <div className="card-body">
                                    <h2 className="card-title">Total Orders</h2>
                                    <p className="text-3xl font-semibold">{salesData.totalOrders}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'Users':
                return (
                    <div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                            <div className="card bordered">
                                <div className="card-body">
                                    <h2 className="card-title">Total Users</h2>
                                    <p className="text-3xl font-semibold">{userData.totalUsers}</p>
                                </div>
                            </div>
                            <div className="card bordered">
                                <div className="card-body">
                                    <h2 className="card-title">Active Users</h2>
                                    <p className="text-3xl font-semibold">{userData.activeUsers}</p>
                                </div>
                            </div>
                            <div className="card bordered">
                                <div className="card-body">
                                    <h2 className="card-title">Inactive Users</h2>
                                    <p className="text-3xl font-semibold">{userData.inactiveUsers}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'Servers':
                return (
                    <div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                            <div className="card bordered">
                                <div className="card-body">
                                    <h2 className="card-title">Total Servers</h2>
                                    <p className="text-3xl font-semibold">{serverData.totalServers}</p>
                                </div>
                            </div>
                            <div className="card bordered">
                                <div className="card-body">
                                    <h2 className="card-title">Active Servers</h2>
                                    <p className="text-3xl font-semibold">{serverData.activeServers}</p>
                                </div>
                            </div>
                            <div className="card bordered">
                                <div className="card-body">
                                    <h2 className="card-title">Inactive Servers</h2>
                                    <p className="text-3xl font-semibold">{serverData.inactiveServers}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return <div>Select a tab to view content</div>;
        }
    };

    return (
        <div>
            <div role="tablist" className="tabs tabs-bordered">
                <a
                    role="tab"
                    className={`tab ${activeTab === 'Sales' ? 'tab-active' : ''}`}
                    onClick={() => setActiveTab('Sales')}
                >
                    Sales
                </a>
                <a
                    role="tab"
                    className={`tab ${activeTab === 'Users' ? 'tab-active' : ''}`}
                    onClick={() => setActiveTab('Users')}
                >
                    Users
                </a>
                <a
                    role="tab"
                    className={`tab ${activeTab === 'Servers' ? 'tab-active' : ''}`}
                    onClick={() => setActiveTab('Servers')}
                >
                    Servers
                </a>
            </div>
            <div className="mt-4">
                {renderContent()}
            </div>
        </div>
    );
};

export default Dashboard;
