import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { auth, db, googleProvider } from '../firebase';
import {
    createUserWithEmailAndPassword,
    signInWithPopup,
    browserLocalPersistence,
    browserSessionPersistence
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import 'react-toastify/dist/ReactToastify.css';
import { PiEye, PiEyeClosedBold } from 'react-icons/pi';

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [profilePicture, setProfilePicture] = useState(null);
    const [profilePictureURL, setProfilePictureURL] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasUppercase: false,
    });
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const savedIdentifier = localStorage.getItem('loginIdentifier');
        if (savedIdentifier) {
            setEmail(savedIdentifier);
            setRememberMe(true);
        }
    }, []);

    useEffect(() => {
        const lengthValid = password.length >= 8;
        const numberValid = /\d/.test(password);
        const specialCharValid = /[!@#$%^&*]/.test(password);
        const uppercaseValid = /[A-Z]/.test(password);
        const passwordsAreEqual = password === confirmPassword;

        setPasswordValidations({
            length: lengthValid,
            hasNumber: numberValid,
            hasSpecialChar: specialCharValid,
            hasUppercase: uppercaseValid,
        });

        setPasswordsMatch(passwordsAreEqual);

        if (email && lengthValid && numberValid && specialCharValid && uppercaseValid && passwordsAreEqual) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [email, password, confirmPassword]);

    const handleProfilePictureUpload = async (file) => {
        const storage = getStorage();
        const storageRef = ref(storage, `profilePictures/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setProfilePictureURL(downloadURL);
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        try {
            const persistenceType = rememberMe ? browserLocalPersistence : browserSessionPersistence;
            await auth.setPersistence(persistenceType);

            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                username: username,
                email: user.email,
                phoneNumber: phoneNumber,
                profilePicture: profilePictureURL,
                admin: false,
                plans: {},
                transactions: {},
                createdAt: new Date(),
            });

            toast.success('Account created successfully!');
            navigate('/dashboard');  // Redirect to dashboard
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            await auth.setPersistence(rememberMe ? browserLocalPersistence : browserSessionPersistence);
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            const userRef = doc(db, "users", user.uid);
            const userSnap = await getDoc(userRef);

            if (!userSnap.exists()) {
                await setDoc(userRef, {
                    uid: user.uid,
                    username: user.displayName || '',
                    email: user.email,
                    phoneNumber: user.phoneNumber || '',
                    profilePicture: user.photoURL || '',
                    createdAt: new Date(),
                    admin: false,
                    plans: [],
                    transactions: []
                });
            }

            toast.success('Logged in successfully with Google!');
            if (rememberMe) {
                localStorage.setItem('loginIdentifier', user.email || user.phoneNumber);
            } else {
                localStorage.removeItem('loginIdentifier');
            }
            navigate('/dashboard');  // Redirect to dashboard
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div className="hero bg-base-200 min-h-screen flex flex-col justify-center items-center">
            <ToastContainer />
            <div className="text-center mb-8">
                <h1 className="text-5xl font-bold">Create an Account</h1>
            </div>
            <div className="card bg-base-100 w-full max-w-sm shadow-2xl">
                <form className="card-body" onSubmit={handleRegister}>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Username</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Enter your username"
                            className="input input-bordered"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Email</span>
                        </label>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="input input-bordered"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Phone Number</span>
                        </label>
                        <input
                            type="tel"
                            placeholder="Enter your phone number"
                            className="input input-bordered"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Profile Picture</span>
                        </label>
                        <input
                            type="file"
                            className="file-input file-input-bordered w-full max-w-xs"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                setProfilePicture(file);
                                handleProfilePictureUpload(file);
                            }}
                        />
                    </div>
                    <div className="form-control relative">
                        <label className="label">
                            <span className="label-text">Password</span>
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            className="input input-bordered pr-10"
                            value={password}
                            onFocus={() => setIsPasswordFocused(true)}
                            onBlur={() => setIsPasswordFocused(false)}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <span
                            className="absolute inset-y-2 top-1/2 right-0 flex items-center justify-center pr-3 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <PiEyeClosedBold size={24} /> : <PiEye size={24} />}
                        </span>
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Confirm Password</span>
                            <span className={`ml-2 text-sm ${passwordsMatch ? 'text-green-500' : 'text-red-500'}`}>
                                {passwordsMatch ? '✔ Passwords match' : '✖ Passwords do not match'}
                            </span>
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirm your password"
                            className="input input-bordered pr-10"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    {isPasswordFocused && (
                        <div className="mt-2">
                            <ul className="text-sm">
                                <li className={`flex items-center ${passwordValidations.length ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.length ? '✔' : '✖'} At least 8 characters
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasNumber ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasNumber ? '✔' : '✖'} Contains a number
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasSpecialChar ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasSpecialChar ? '✔' : '✖'} Contains a special character
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasUppercase ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasUppercase ? '✔' : '✖'} Contains an uppercase letter
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="form-control">
                        <label className="cursor-pointer label">
                            <input
                                type="checkbox"
                                className="checkbox checkbox-primary"
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            <span className="label-text ml-2">Remember Me</span>
                        </label>
                    </div>
                    <div className="form-control mt-6">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isButtonDisabled}
                        >
                            Sign up
                        </button>
                    </div>
                    <div className="form-control mt-6">
                        <button type="button" className="btn btn-secondary" onClick={handleGoogleLogin}>
                            Sign up with Google
                        </button>
                    </div>
                </form>
            </div>
            <div id="recaptcha-container"></div>
        </div>
    );
};

export default Register;