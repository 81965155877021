import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../context/AuthProvider';

const Products = () => {
    const currentUser = useAuth();
    const [servers, setServers] = useState([]);
    const [selectedServerId, setSelectedServerId] = useState(null);
    const [step, setStep] = useState(1); // Step state to track the current section
    const [loading, setLoading] = useState(true); // Loading state to track if content is still loading
    const [selectedLevel, setSelectedLevel] = useState(1); // State to track the selected level

    const serverPlans = [
        { level: 1, plans: [{ duration: '1 Month', price: 4.99 }] },
        { level: 2, plans: [{ duration: '3 Months', price: 11.99 }] },
        { level: 3, plans: [{ duration: '6 Months', price: 24.99 }] },
    ];

    const stripeLinks = {
        'London': {
            1: 'https://pay.privsea.net/b/9AQ2bbf3a7Zxe9G4gp',
            2: 'https://pay.privsea.net/b/8wM3ff8EM0x52qY3cm',
            3: 'https://pay.privsea.net/b/4gw8zz7AI4Nl3v228j'
        },
        'Oregon': {
            1: 'https://pay.privsea.net/b/fZebLL8EM4Nl5Da00c',
            2: 'https://pay.privsea.net/b/aEU2bb9IQcfN5Da5kx',
            3: 'https://pay.privsea.net/b/14keXX3ks1B95Da5ky'
        },
        'Virginia': {
            1: 'https://pay.privsea.net/b/3csaHHbQYbbJd5CaET',
            2: 'https://pay.privsea.net/b/9AQ5nn2go93Be9G5kA',
            3: 'https://pay.privsea.net/b/bIYeXX5sAfrZfdKbIZ'
        }
    };

    useEffect(() => {
        const fetchServers = async () => {
            try {
                const serversCollection = collection(db, 'servers');
                const serversSnapshot = await getDocs(serversCollection);
                const serversList = serversSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setServers(serversList);
            } catch (error) {
                console.error('Error fetching servers:', error);
                toast.error('Error fetching servers.');
            } finally {
                setLoading(false); // Set loading to false once data is fetched
            }
        };

        fetchServers();
    }, []);

    const handleServerClick = (serverId) => {
        setSelectedServerId(serverId);
    };

    const handleNextStep = () => {
        if (step < 4) {
            setStep(step + 1); // Increment the step to move to the next section
        }
    };

    const handlePreviousStep = () => {
        if (step > 1) {
            setStep(step - 1); // Decrement the step to move back to the previous section
        }
    };

    const handleLevelChange = (level) => {
        setSelectedLevel(level);
    };

    const selectedServer = servers.find(server => server.id === selectedServerId);
    const currentPlans = serverPlans.find(plan => plan.level === selectedLevel)?.plans || [];

    const selectedPlan = currentPlans[0]; // Assuming there is only one plan per level

    const handlePurchase = () => {
        const serverName = selectedServer.name;
        const purchaseUrl = stripeLinks[serverName]?.[selectedLevel];
        if (purchaseUrl) {
            toast.success('Redirecting to payment page...');

            // Add user metadata to the purchase URL (this assumes you're using Stripe Checkout or a similar service)
            const metadata = {
                userId: currentUser.uid,  // Assume currentUser is your logged-in user
                serverId: selectedServerId,
                serverName: selectedServer.name,
                serverType: selectedServer.type,
                serverLocation: selectedServer.location,
                duration: selectedPlan.duration,
                price: selectedPlan.price,
            };

            const metadataParams = new URLSearchParams(metadata).toString();
            const redirectToUrl = `${purchaseUrl}?${metadataParams}`;

            // Open the payment link in a new tab
            window.open(redirectToUrl, '_blank');
        } else {
            toast.error('No purchase link available for the selected options.');
        }
    };

    return (
        <div className='flex flex-col justify-center mt-20'>
            <div className='flex flex-col items-center'>
                {/* Display loading spinner if content is loading */}
                {loading ? (
                    <span className="loading loading-infinity loading-lg"></span>
                ) : (
                    <>
                        {step === 1 && (
                            <div className='flex flex-col items-center'>
                                <h2 className='text-2xl font-semibold mb-10'>Step 1: Select Server</h2>
                                <div className='flex flex-row justify-center items-center gap-4'>
                                    {servers.map((server) => (
                                        <div
                                            key={server.id}
                                            onClick={() => handleServerClick(server.id)}
                                            className={`card bg-base-100 w-96 shadow-xl h-fit cursor-pointer transition-transform duration-300 ${selectedServerId === server.id ? 'transform -translate-y-2 border-2 border-indigo-600' : ''
                                                }`}
                                        >
                                            <figure>
                                                <img
                                                    className='w-full h-40 object-cover'
                                                    src={server.flagUrl}
                                                    alt={server.name}
                                                />
                                            </figure>
                                            <div className="card-body">
                                                <h2 className="card-title">
                                                    {server.name}
                                                    <div className="badge badge-secondary">{server.slots} slots</div>
                                                </h2>
                                                <p>{server.description || 'Fast speeds, 24/7 uptime & support, OpenVPN compatibility'}</p>
                                                <div className="card-actions justify-end">
                                                    <div className="badge badge-outline">{server.location}</div>
                                                    <div className="badge badge-outline">{server.type}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {step === 2 && selectedServer && (
                            <div className='flex flex-col items-center'>
                                <h2 className='text-2xl font-semibold mb-10'>Step 2: Choose Duration</h2>
                                <div className="join mb-5">
                                    <input
                                        className="join-item btn btn-square px-4 w-fit"
                                        type="radio"
                                        name="options"
                                        aria-label="Level 1"
                                        checked={selectedLevel === 1}
                                        onChange={() => handleLevelChange(1)}
                                    />
                                    <input
                                        className="join-item btn btn-square px-4 w-fit"
                                        type="radio"
                                        name="options"
                                        aria-label="Level 2"
                                        checked={selectedLevel === 2}
                                        onChange={() => handleLevelChange(2)}
                                    />
                                    <input
                                        className="join-item btn btn-square px-4 w-fit"
                                        type="radio"
                                        name="options"
                                        aria-label="Level 3"
                                        checked={selectedLevel === 3}
                                        onChange={() => handleLevelChange(3)}
                                    />
                                </div>
                                <div className="overflow-x-auto">
                                    {currentPlans.map((plan, index) => (
                                        <table key={index} className="table">
                                            <tbody>
                                                <tr>
                                                    <th>Duration</th>
                                                    <td>{plan.duration}</td>
                                                </tr>
                                                <tr>
                                                    <th>Connection</th>
                                                    <td>OpenVPN</td>
                                                </tr>
                                                <tr>
                                                    <th>Price</th>
                                                    <td>${plan.price}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    ))}
                                </div>
                            </div>
                        )}

                        {step === 3 && selectedServer && selectedPlan && (
                            <div className='flex flex-col items-center'>
                                <h2 className='text-2xl font-semibold mb-10'>Step 3: Purchase</h2>
                                <div className="overflow-x-auto">
                                    <table className="table text-center">
                                        {/* head */}
                                        <thead>
                                            <tr>
                                                <th>Selected Server</th>
                                                <th>Server Type</th>
                                                <th>Selected Duration</th>
                                                <th>Slots Available</th>
                                                <th>Final Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="flex items-center gap-3">
                                                        <div className="avatar">
                                                            <div className="h-12 w-16">
                                                                <img
                                                                    className='w-full h-40 object-cover rounded-xl'
                                                                    src={selectedServer.flagUrl ? selectedServer.flagUrl : "https://files.catbox.moe/4fw33q.png"}
                                                                    alt={selectedServer.name}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="font-bold">{selectedServer.name}</div>
                                                            <div className="text-sm opacity-50 text-left">{selectedServer.location}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {selectedServer.type}
                                                </td>
                                                <td>{selectedPlan.duration}</td>
                                                <th>
                                                    <span className="badge badge-primary badge-md">{selectedServer.slots}/200</span>
                                                </th>
                                                <th>
                                                    <span>${selectedPlan.price}</span>
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {step === 4 && (
                            <div className='flex flex-col items-center'>
                                <h2 className='text-2xl font-semibold mb-10'>Step 4: Receive Access</h2>
                                <p>Finalize the process and provide access details.</p>
                            </div>
                        )}

                        {/* Navigation Buttons */}
                        <div className="flex flex-row justify-between w-full max-w-4xl mt-6">
                            {step > 1 && (
                                <button
                                    className="btn btn-secondary"
                                    onClick={handlePreviousStep}
                                >
                                    Previous Step
                                </button>
                            )}
                            {step < 4 && selectedServerId && (
                                step === 3 ? (
                                    <button
                                        className="btn btn-primary mt-4"
                                        onClick={handlePurchase}
                                    >
                                        Complete Purchase
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-primary ml-auto"
                                        onClick={handleNextStep}
                                    >
                                        Next Step
                                    </button>
                                )
                            )}

                        </div>

                        {/* Step Indicator */}
                        <ul className="steps mt-10">
                            <li className={`step ${step >= 1 ? 'step-primary' : ''}`}>Select Server</li>
                            <li className={`step ${step >= 2 ? 'step-primary' : ''}`}>Choose Duration</li>
                            <li className={`step ${step >= 3 ? 'step-primary' : ''}`}>Purchase</li>
                            <li className={`step ${step >= 4 ? 'step-primary' : ''}`}>Receive Access</li>
                        </ul>
                    </>
                )}
                <ToastContainer />
            </div>
        </div>
    );
}

export default Products;
