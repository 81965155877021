import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { PiEye, PiEyeClosedBold } from 'react-icons/pi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

const Profile = () => {
    const { currentUser } = useAuth();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [showPasswordSection, setShowPasswordSection] = useState(false); // To toggle password update section
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phoneNumber: '',
        profilePicture: null,
    });
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isPasswordButtonDisabled, setIsPasswordButtonDisabled] = useState(true);
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasUppercase: false,
    });
    const [isPasswordFocused, setIsPasswordFocused] = useState(false); // Manage password field focus

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser && currentUser.uid) {
                try {
                    const userDocRef = doc(db, 'users', currentUser.uid);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        setUserData(userDoc.data());
                        setFormData({
                            username: userDoc.data().username || '',
                            email: userDoc.data().email || '',
                            phoneNumber: userDoc.data().phoneNumber || '',
                            profilePicture: userDoc.data().profilePicture || null,
                        });
                        toast.success('User data loaded successfully');
                    } else {
                        console.log('No such document!');
                        toast.error('No user data found.');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    toast.error('Error fetching user data.');
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
                toast.warn('No user is currently logged in.');
            }
        };

        fetchUserData();
    }, [currentUser]);

    useEffect(() => {
        const lengthValid = passwordData.newPassword.length >= 8;
        const numberValid = /\d/.test(passwordData.newPassword);
        const specialCharValid = /[!@#$%^&*]/.test(passwordData.newPassword);
        const uppercaseValid = /[A-Z]/.test(passwordData.newPassword);
        const passwordsAreEqual = passwordData.newPassword === passwordData.confirmPassword;

        setPasswordValidations({
            length: lengthValid,
            hasNumber: numberValid,
            hasSpecialChar: specialCharValid,
            hasUppercase: uppercaseValid,
        });

        setPasswordsMatch(passwordsAreEqual);

        if (lengthValid && numberValid && specialCharValid && uppercaseValid && passwordsAreEqual) {
            setIsPasswordButtonDisabled(false);
        } else {
            setIsPasswordButtonDisabled(true);
        }
    }, [passwordData.newPassword, passwordData.confirmPassword]);

    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return '';
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    };

    const handleEditToggle = () => {
        setEditing(!editing);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleProfilePictureUpload = async () => {
        if (!formData.profilePicture) return '';

        const storageRef = ref(storage, `profilePictures/${currentUser.uid}_${formData.profilePicture.name}`);
        await uploadBytes(storageRef, formData.profilePicture);
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    };

    const handleUpdatePassword = async () => {
        if (currentUser && passwordData.newPassword && passwordsMatch) {
            try {
                const credentials = EmailAuthProvider.credential(currentUser.email, passwordData.currentPassword);
                await reauthenticateWithCredential(currentUser, credentials);
                await updatePassword(currentUser, passwordData.newPassword);
                toast.success('Password updated successfully!');
                setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '' });
                setShowPasswordSection(false);
            } catch (error) {
                if (error.code === 'auth/wrong-password') {
                    toast.error('Current password is incorrect.');
                } else {
                    toast.error('Failed to update password: ' + error.message);
                }
            }
        }
    };


    const handleUpdateUserInfo = async (e) => {
        e.preventDefault();
        try {
            const userRef = doc(db, 'users', currentUser.uid);

            let profilePictureURL = userData.profilePicture;
            if (formData.profilePicture && typeof formData.profilePicture !== 'string') {
                profilePictureURL = await handleProfilePictureUpload();
            }

            await updateDoc(userRef, {
                username: formData.username,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
                profilePicture: profilePictureURL,
            });

            setUserData({ ...userData, ...formData, profilePicture: profilePictureURL });
            setEditing(false);
            toast.success('User information updated successfully!');
        } catch (error) {
            console.error('Error updating user information:', error);
            toast.error('Failed to update user information.');
        }
    };

    if (loading) {
        return (
            <div className='flex justify-center items-center h-1/3'>
                <span className="loading loading-infinity loading-lg"></span>
            </div>
        );
    }

    if (!userData) {
        return <div className='text-center'>No user data found.</div>;
    }

    return (
        <div>
            <div className="overflow-x-auto">
                <table className="table">
                    {/* head */}
                    <thead>
                        <tr>
                            <th>User Info</th>
                            <th>Phone Number</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="flex items-center gap-3">
                                    <div className="avatar">
                                        <div className="mask mask-squircle h-12 w-12">
                                            <img
                                                src={userData.profilePicture ? userData.profilePicture : "https://i.pinimg.com/736x/38/47/9c/38479c637a4ef9c5ced95ca66ffa2f41.jpg"}
                                                alt={userData.username || 'User'} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="font-bold">{userData.username}</div>
                                        <div className="text-sm opacity-50">{userData.email}</div>
                                    </div>
                                </div>
                            </td>
                            <td>{formatPhoneNumber(userData.phoneNumber)}</td>
                            <th>
                                <button className="btn btn-ghost btn-xs" onClick={handleEditToggle}>
                                    {editing ? 'Cancel' : 'Edit'}
                                </button>
                            </th>
                        </tr>
                        {/* Edit Form */}
                        {editing && (
                            <tr>
                                <td colSpan="3">
                                    <form onSubmit={handleUpdateUserInfo} className="p-4 bg-base-200 rounded-lg mt-4">
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Username</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="username"
                                                value={formData.username}
                                                onChange={handleChange}
                                                className="input input-bordered w-full"
                                            />
                                        </div>
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Email</span>
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="input input-bordered w-full"
                                            />
                                        </div>
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Phone Number</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="phoneNumber"
                                                value={formData.phoneNumber}
                                                onChange={handleChange}
                                                className="input input-bordered w-full"
                                            />
                                        </div>
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Profile Picture</span>
                                            </label>
                                            <input
                                                type="file"
                                                name="profilePicture"
                                                className="file-input file-input-bordered w-full max-w-xs"
                                                onChange={(e) => setFormData({ ...formData, profilePicture: e.target.files[0] })}
                                                accept="image/*"
                                            />
                                        </div>
                                        <div className="form-control mt-6">
                                            <button
                                                className="btn btn-primary w-full"
                                                type="submit"
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    </form>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="mt-8">
                <button
                    className="btn btn-secondary"
                    onClick={() => setShowPasswordSection(!showPasswordSection)}
                >
                    {showPasswordSection ? 'Cancel Password Update' : 'Update Password'}
                </button>
            </div>
            {/* Password Update Section */}
            {showPasswordSection && (
                <div className="mt-4">
                    <div className="form-control relative mt-4">
                        <label className="label">
                            <span className="label-text">Current Password</span>
                        </label>
                        <input
                            type={showCurrentPassword ? "text" : "password"}
                            name="currentPassword"
                            value={passwordData.currentPassword}
                            onFocus={() => setIsPasswordFocused(true)}
                            onBlur={() => setIsPasswordFocused(false)}
                            onChange={handlePasswordChange}
                            className="input input-bordered w-full"
                        />
                        <span
                            className="absolute inset-y-2 top-1/2 right-0 pr-3 flex items-center justify-center cursor-pointer"
                            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        >
                            {showCurrentPassword ? <PiEyeClosedBold size={24} /> : <PiEye size={24} />}
                        </span>
                    </div>
                    <div className="form-control relative mt-4">
                        <label className="label">
                            <span className="label-text">New Password</span>
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            name="newPassword"
                            value={passwordData.newPassword}
                            onFocus={() => setIsPasswordFocused(true)}
                            onBlur={() => setIsPasswordFocused(false)}
                            onChange={handlePasswordChange}
                            className="input input-bordered pr-10"
                        />
                        <span
                            className="absolute inset-y-2 top-1/2 right-0 pr-3 flex items-center justify-center cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <PiEyeClosedBold size={24} /> : <PiEye size={24} />}
                        </span>
                    </div>
                    <div className="form-control relative mt-4">
                        <label className="label flex items-center">
                            <span className="label-text">Confirm New Password</span>
                            <span className={`ml-2 text-sm ${passwordsMatch ? 'text-green-500' : 'text-red-500'}`}>
                                {passwordsMatch ? '✔ Passwords match' : '✖ Passwords don\'t match'}
                            </span>
                        </label>
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={passwordData.confirmPassword}
                            onFocus={() => setIsPasswordFocused(true)}
                            onBlur={() => setIsPasswordFocused(false)}
                            onChange={handlePasswordChange}
                            className="input input-bordered pr-10"
                        />
                        <span
                            className="absolute inset-y-2 top-1/2 right-0 pr-3 flex items-center cursor-pointer"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            {showConfirmPassword ? <PiEyeClosedBold size={24} /> : <PiEye size={24} />}
                        </span>
                    </div>
                    {isPasswordFocused && (
                        <div className="mt-2">
                            <ul className="text-sm">
                                <li className={`flex items-center ${passwordValidations.length ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.length ? '✔' : '✖'} At least 8 characters
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasNumber ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasNumber ? '✔' : '✖'} Contains a number
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasSpecialChar ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasSpecialChar ? '✔' : '✖'} Contains a special character
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasUppercase ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasUppercase ? '✔' : '✖'} Contains an uppercase letter
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="form-control mt-6">
                        <button
                            className="btn btn-primary w-full"
                            onClick={handleUpdatePassword}
                            disabled={isPasswordButtonDisabled}
                        >
                            Update Password
                        </button>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default Profile;
