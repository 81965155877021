import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { auth, db, googleProvider, configureRecaptcha } from '../firebase';
import {
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    signInWithPopup,
    browserLocalPersistence,
    browserSessionPersistence
} from 'firebase/auth';
import { collection, query, where, getDocs, doc, getDoc, setDoc } from 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.css';
import { PiEye, PiEyeClosedBold } from 'react-icons/pi';

const Login = () => {
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasUppercase: false,
    });
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const savedIdentifier = localStorage.getItem('loginIdentifier');
        if (savedIdentifier) {
            setIdentifier(savedIdentifier);
            setRememberMe(true);
        }
    }, []);

    useEffect(() => {
        const lengthValid = password.length >= 8;
        const numberValid = /\d/.test(password);
        const specialCharValid = /[!@#$%^&*]/.test(password);
        const uppercaseValid = /[A-Z]/.test(password);

        setPasswordValidations({
            length: lengthValid,
            hasNumber: numberValid,
            hasSpecialChar: specialCharValid,
            hasUppercase: uppercaseValid,
        });

        if (identifier && lengthValid && numberValid && specialCharValid && uppercaseValid) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [identifier, password]);

    const findEmailOrPhoneByUsername = async (username) => {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("username", "==", username));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();
            return userData.email || userData.phoneNumber;
        } else {
            throw new Error("No user found with this username");
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        let loginIdentifier = identifier;

        if (!identifier.includes('@') && isNaN(identifier)) {
            try {
                loginIdentifier = await findEmailOrPhoneByUsername(identifier);
            } catch (error) {
                toast.error(error.message);
                return;
            }
        }

        try {
            const persistenceType = rememberMe ? browserLocalPersistence : browserSessionPersistence;
            await auth.setPersistence(persistenceType);

            if (loginIdentifier.includes('@')) {
                await signInWithEmailAndPassword(auth, loginIdentifier, password);
                toast.success('Logged in successfully with email!');
                navigate('/dashboard');  // Redirect to dashboard
            } else {
                configureRecaptcha();
                const appVerifier = window.recaptchaVerifier;
                const confirmationResult = await signInWithPhoneNumber(auth, loginIdentifier, appVerifier);
                const verificationCode = prompt('Enter the verification code sent to your phone');
                await confirmationResult.confirm(verificationCode);
                toast.success('Logged in successfully with phone!');
                navigate('/dashboard');  // Redirect to dashboard
            }

            if (rememberMe) {
                localStorage.setItem('loginIdentifier', identifier);
            } else {
                localStorage.removeItem('loginIdentifier');
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            await auth.setPersistence(rememberMe ? browserLocalPersistence : browserSessionPersistence);
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            const userRef = doc(db, "users", user.uid);
            const userSnap = await getDoc(userRef);

            if (!userSnap.exists()) {
                await setDoc(userRef, {
                    uid: user.uid,
                    username: user.displayName || '',
                    email: user.email,
                    phoneNumber: user.phoneNumber || '',
                    profilePicture: user.photoURL || '',
                    createdAt: new Date(),
                    admin: false,
                    plans: {},
                    transactions: {}
                });
            }

            toast.success('Logged in successfully with Google!');
            if (rememberMe) {
                localStorage.setItem('loginIdentifier', user.email || user.phoneNumber);
            } else {
                localStorage.removeItem('loginIdentifier');
            }
            navigate('/dashboard');  // Redirect to dashboard
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div className="hero bg-base-200 min-h-screen flex flex-col justify-center items-center">
            <ToastContainer />
            <div className="text-center mb-8">
                <h1 className="text-5xl font-bold">Welcome back</h1>
            </div>
            <div className="card bg-base-100 w-full max-w-sm shadow-2xl">
                <form className="card-body" onSubmit={handleLogin}>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Username, Email, or Phone Number</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Enter your login identifier"
                            className="input input-bordered"
                            value={identifier}
                            onChange={(e) => setIdentifier(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-control relative">
                        <label className="label">
                            <span className="label-text">Password</span>
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            className="input input-bordered pr-10"
                            value={password}
                            onFocus={() => setIsPasswordFocused(true)}
                            onBlur={() => setIsPasswordFocused(false)}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <span
                            className="absolute inset-y-2 top-1/2 right-0 flex items-center justify-center pr-3 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <PiEyeClosedBold size={24} /> : <PiEye size={24} />}
                        </span>
                    </div>
                    {isPasswordFocused && (
                        <div className="mt-2">
                            <ul className="text-sm">
                                <li className={`flex items-center ${passwordValidations.length ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.length ? '✔' : '✖'} At least 8 characters
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasNumber ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasNumber ? '✔' : '✖'} Contains a number
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasSpecialChar ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasSpecialChar ? '✔' : '✖'} Contains a special character
                                </li>
                                <li className={`flex items-center ${passwordValidations.hasUppercase ? 'text-green-500' : 'text-red-500'}`}>
                                    {passwordValidations.hasUppercase ? '✔' : '✖'} Contains an uppercase letter
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="form-control">
                        <label className="cursor-pointer label">
                            <input
                                type="checkbox"
                                className="checkbox checkbox-primary"
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            <span className="label-text ml-2">Remember Me</span>
                        </label>
                    </div>
                    <div className="form-control mt-6">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isButtonDisabled}
                        >
                            Login
                        </button>
                    </div>
                    <div className="form-control mt-6">
                        <button type="button" className="btn btn-secondary" onClick={handleGoogleLogin}>
                            Login with Google
                        </button>
                    </div>
                    <label className="label">
                        <a href="/forgot" className="label-text-alt link link-hover">Forgot password?</a>
                        <a href="/register" className="label-text-alt link link-hover">Register</a>
                    </label>
                </form>
            </div>
            <div id="recaptcha-container"></div>
        </div>
    );
};

export default Login;