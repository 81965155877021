import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthProvider';
import Setup from '../components/Setup';
import Profile from '../components/Profile';
import Services from '../components/Services';
import Transactions from '../components/Transactions';
import ProfileSidebar from '../components/ProfileSidebar';

const Dashboard = () => {
    const { currentUser } = useAuth();
    const [isAccountComplete, setIsAccountComplete] = useState(true);
    const [activeTab, setActiveTab] = useState('profile');

    const renderContent = () => {
        switch (activeTab) {
            case 'profile':
                return <Profile />;
            case 'services':
                return <Services />;
            case 'transactions':
                return <Transactions />;
            default:
                return <Profile />;
        }
    };

    useEffect(() => {
        if (currentUser && !currentUser.completed) {
            setIsAccountComplete(false);
        }
    }, [currentUser]);

    return (
        <div>
            {isAccountComplete ? (
                <div className="flex min-h-screen">
                    <ProfileSidebar setActiveTab={setActiveTab} />
                    <div className="flex-grow flex justify-center p-4 ml-80">
                        <div className="max-w-4xl w-full">
                            {renderContent()}
                        </div>
                    </div>
                </div>
            ) : (
                <Setup />
            )}
        </div>
    );
}

export default Dashboard;
