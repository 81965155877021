import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase'; // Ensure storage is imported if you need file upload
import { collection, getDocs, deleteDoc, doc, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // For handling file uploads
import { toast } from 'react-toastify';

const Servers = () => {
    const [servers, setServers] = useState([]);
    const [serverAdd, setServerAdd] = useState(false);
    const [selectedServer, setSelectedServer] = useState(null); // For tracking server to delete

    // State for form fields
    const [serverName, setServerName] = useState('');
    const [serverType, setServerType] = useState('');
    const [serverLocation, setServerLocation] = useState('');
    const [monthlyPayLink, setMonthlyPayLink] = useState('');
    const [triMonthlyPayLink, setTriMonthlyPayLink] = useState('');
    const [sexMonthlyPayLink, setSexMonthlyPayLink] = useState('');
    const [flagFile, setFlagFile] = useState(null);

    useEffect(() => {
        const fetchServers = async () => {
            try {
                const serversCollection = collection(db, 'servers');
                const serversSnapshot = await getDocs(serversCollection);
                const serversList = serversSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setServers(serversList);
            } catch (error) {
                console.error('Error fetching servers:', error);
            }
        };

        fetchServers();
    }, []);

    const terminateServer = (serverId) => {
        setSelectedServer(serverId);
        document.getElementById('my_modal_3').showModal();
    };

    const confirmTerminate = async () => {
        if (selectedServer) {
            try {
                await deleteDoc(doc(db, 'servers', selectedServer));
                setServers(servers.filter(server => server.id !== selectedServer));
                toast.success('Server terminated successfully!');
            } catch (error) {
                console.error('Error terminating server:', error);
                toast.error('Error terminating server');
            } finally {
                document.getElementById('my_modal_3').close();
            }
        }
    };

    const toggleServerAdd = () => {
        setServerAdd(!serverAdd);
    };

    const handleAddServer = async (e) => {
        e.preventDefault();

        try {
            let flagUrl = '';
            if (flagFile) {
                const flagRef = ref(storage, `serverFlags/${flagFile.name}`);
                await uploadBytes(flagRef, flagFile);
                flagUrl = await getDownloadURL(flagRef);
            }

            const newServer = {
                name: serverName,
                type: serverType,
                location: serverLocation,
                flagUrl: flagUrl || "https://files.catbox.moe/4fw33q.png",
                slots: 200,
                downloadUrl: `http://localhost/openvpn-configs/${serverName}.ovpn`,
                plans: [
                    {
                        duration: 30,
                        payLink: monthlyPayLink,
                        price: 4.99
                    },
                    {
                        duration: 90,
                        payLink: triMonthlyPayLink,
                        price: 11.99
                    },
                    {
                        duration: 180,
                        payLink: sexMonthlyPayLink,
                        price: 24.99
                    }
                ]
            };

            const docRef = await addDoc(collection(db, 'servers'), newServer);
            setServers([...servers, { id: docRef.id, ...newServer }]);
            toast.success('Server added successfully!');
            toggleServerAdd(); // Close the form
        } catch (error) {
            console.error('Error adding server:', error);
            toast.error('Error adding server');
        }
    };

    return (
        <div className="w-full flex flex-col">
            <div className="overflow-x-auto">
                <table className="table">
                    {/* head */}
                    <thead>
                        <tr>
                            <th>Server Info</th>
                            <th>Server Type</th>
                            <th>Server Slots</th>
                            <th></th>
                        </tr>
                    </thead>
                    {servers.map((server) => (
                        <tbody key={server.id}>
                            <tr>
                                <td>
                                    <div className="flex items-center gap-3">
                                        <div className="avatar">
                                            <div className="rounded-full h-12 w-12">
                                                <img
                                                    src={server.flagUrl || "https://files.catbox.moe/4fw33q.png"}
                                                    alt={server.name} />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="font-bold">{server.name}</div>
                                            <div className="text-sm opacity-50">{server.location}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>{server.type}</td>
                                <td>{server.slots}/200</td>
                                <th>
                                    <button className="btn btn-ghost btn-xs" onClick={() => terminateServer(server.id)}>Terminate</button>
                                </th>
                            </tr>
                        </tbody>
                    ))}
                </table>
                <dialog id="my_modal_3" className="modal">
                    <div className="modal-box">
                        <form method="dialog">
                            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                        </form>
                        <h3 className="font-bold text-lg">Are you sure you want to terminate this server?</h3>
                        <p className="py-4 mb-10">Terminating this server will remove it from anyone's profile and from the active server list. You cannot undo this action.</p>
                        <button onClick={confirmTerminate} className="btn btn-error">Terminate Server</button>
                    </div>
                </dialog>
            </div>
            {serverAdd ? (
                <div>
                    <form className="card-body" onSubmit={handleAddServer}>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Server Name</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter the server name (Virginia, Oregon, London, etc.)"
                                className="input input-bordered"
                                value={serverName}
                                onChange={(e) => setServerName(e.target.value)}
                                required
                            />
                            <label className="label">
                                <span className="label-text">Server Type</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter the server type (OVH, NFO, etc.)"
                                className="input input-bordered"
                                value={serverType}
                                onChange={(e) => setServerType(e.target.value)}
                                required
                            />
                            <label className="label">
                                <span className="label-text">Server Location</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter the server location (United States, United Kingdom, Canada, etc.)"
                                className="input input-bordered"
                                value={serverLocation}
                                onChange={(e) => setServerLocation(e.target.value)}
                                required
                            />
                            <label className="label">
                                <span className="label-text">Monthly Pay Link</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter the Stripe Pay Link for monthly payments"
                                className="input input-bordered"
                                value={monthlyPayLink}
                                onChange={(e) => setMonthlyPayLink(e.target.value)}
                                required
                            />
                            <label className="label">
                                <span className="label-text">3 Month Pay Link</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter the Stripe Pay Link for 3-month payments"
                                className="input input-bordered"
                                value={triMonthlyPayLink}
                                onChange={(e) => setTriMonthlyPayLink(e.target.value)}
                                required
                            />
                            <label className="label">
                                <span className="label-text">6 Month Pay Link</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter the Stripe Pay Link for 6-month payments"
                                className="input input-bordered"
                                value={sexMonthlyPayLink}
                                onChange={(e) => setSexMonthlyPayLink(e.target.value)}
                                required
                            />
                            <label className="label">
                                <span className="label-text">Upload server flag</span>
                            </label>
                            <input
                                type="file"
                                className="file-input file-input-bordered w-full max-w-xs"
                                onChange={(e) => setFlagFile(e.target.files[0])}
                            />
                        </div>
                        <button className="btn btn-primary mt-4" type="submit">Add Server</button>
                        <button className="btn btn-secondary mt-4" onClick={toggleServerAdd}>Cancel</button>
                    </form>
                </div>
            ) : (
                <button className="btn btn-secondary mt-4" onClick={toggleServerAdd}>Add Server</button>
            )}
        </div>
    );
};

export default Servers;
