import React, { useEffect, useState } from 'react';
import { db } from '../firebase'; // Make sure this path is correct
import { collection, getDocs } from 'firebase/firestore';

const About = () => {
    const [totalUsers, setTotalUsers] = useState(null);

    useEffect(() => {
        const fetchTotalUsers = async () => {
            try {
                const usersCollection = collection(db, 'users');
                const usersSnapshot = await getDocs(usersCollection);
                const totalUsersCount = usersSnapshot.size; // Get the number of documents in the collection
                setTotalUsers(totalUsersCount);
            } catch (error) {
                console.error('Failed to fetch total users', error);
            }
        };

        fetchTotalUsers();
    }, []);

    const loadingthingy = () => {
        return (
            <div className="flex justify-center items-center">
                <span className="loading loading-infinity loading-lg"></span>
            </div>
        );
    };

    return (
        <div name='about' className='w-full my-32 pt-32 bg-slate-800 max-h-96 text-primary'>
            <div className='max-w-[1240px] mx-auto'>
                <div className='text-center'>
                    <h2 className='text-5xl font-bold'>Trusted by developers across the world</h2>
                    <p className='text-3xl py-6 text-neutral-300'>All in one secure platform. Access the internet with our offshore VPN</p>
                </div>
                <div className="stats shadow w-[100%] py-10">
                    <div className="stat place-items-center">
                        <div className="stat-title">Uptime</div>
                        <div className="stat-value">100%</div>
                        <div className="stat-desc">Uptime API & Mitigation Notifications</div>
                    </div>
                    <div className="stat place-items-center">
                        <div className="stat-title">Support</div>
                        <div className="stat-value">24/7</div>
                        <div className="stat-desc"></div>
                    </div>
                    <div className="stat place-items-center">
                        <div className="stat-title">Users</div>
                        <div className="stat-value text-6xl font-bold">
                            {totalUsers !== null ? (
                                <p>{totalUsers}</p>
                            ) : (
                                loadingthingy()
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
