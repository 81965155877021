import React from 'react'
import { FaSearch, FaQuestion, FaServer, FaCreditCard, FaTools, FaArrowRight } from "react-icons/fa";

const handleFaq = () => { }

const handleInfo = () => { }

const handleBilling = () => { }

const handleConnectivity = () => { }

const handleStartUsing = () => { }

const handleConnectIssues = () => { }

const Support = () => {
    return (
        <div className='flex flex-col items-center'>
            <div className="mt-10">
                <div className="text-center">
                    <div className="max-w-md">
                        <h1 className="text-3xl font-bold my-5">Hi, how can we help you?</h1>
                        <div className='flex flex-row justify-center items-center relative'>
                            <FaSearch className='text-2xl absolute left-10' />
                            <input
                                type="text"
                                placeholder="Type your question here"
                                className="input input-bordered input-primary w-full max-w-xs pl-16"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-24">
                <h1 className="text-5xl font-bold">Choose main category</h1>
                <p className="py-6">Dig deeper and easily find your answers.</p>
                <div className="flex flex-row gap-4">
                    <div className='flex flex-col justify-center items-center cursor-pointer' onClick={handleFaq}>
                        <div className='flex flex-row justify-center items-center bg-base-300 w-64 h-32 rounded-3xl hover:scale-105 hover:-translate-y-2 hover:border-primary hover:border-2'>
                            <FaQuestion className="text-5xl text-primary" />
                        </div>
                        <div className='text-center py-2 w-64 h-32'>
                            <h2 className="text-2xl font-bold">Faq</h2>
                            <p className='text-xs p-2'>Most frequently asked questions and PrivseaVPN setup tutorials. Learn more about getting started with PrivseaVPN.</p>
                        </div>
                    </div>
                    <div className='flex flex-col justify-center items-center cursor-pointer' onClick={handleInfo}>
                        <div className='flex flex-row justify-center items-center bg-base-300 w-64 h-32 rounded-3xl hover:scale-105 hover:-translate-y-2 hover:border-primary hover:border-2'>
                            <FaServer className="text-5xl text-primary" />
                        </div>
                        <div className='text-center py-2 w-64 h-32'>
                            <h2 className="text-2xl font-bold">General Info</h2>
                            <p className='text-xs p-2'>Click here to learn all you need to know about PrivseaVPN's features and streaming options with our easy-to-follow guides.</p>
                        </div>
                    </div>
                    <div className='flex flex-col justify-center items-center cursor-pointer' onClick={handleBilling}>
                        <div className='flex flex-row justify-center items-center bg-base-300 w-64 h-32 rounded-3xl hover:scale-105 hover:-translate-y-2 hover:border-primary hover:border-2'>
                            <FaCreditCard className="text-5xl text-primary" />
                        </div>
                        <div className='text-center py-2 w-64 h-32'>
                            <h2 className="text-2xl font-bold">Billing</h2>
                            <p className='text-xs p-2'>Click here to learn how to manage your subscriptions and payments, and learn about PrivseaVPN plans.</p>
                        </div>
                    </div>
                    <div className='flex flex-col justify-center items-center cursor-pointer' onClick={handleConnectivity}>
                        <div className='flex flex-row justify-center items-center bg-base-300 w-64 h-32 rounded-3xl hover:scale-105 hover:-translate-y-2 hover:border-primary hover:border-2'>
                            <FaTools className="text-5xl text-primary" />
                        </div>
                        <div className='text-center py-2 w-64 h-32'>
                            <h2 className="text-2xl font-bold">Connectivity</h2>
                            <p className='text-xs p-2'>Click here to troubleshoot your device's connection issues easily and get back to enjoying PrivseaVPN.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col text-center mt-14 mb-10">
                <h1 className="text-5xl font-bold">Quick Help</h1>
                <div className='flex flex-col justify-center items-center h-fit mt-5 gap-4'>
                    <div className='bg-base-200 w-96 h-fit p-5 relative cursor-pointer hover:bg-base-300 hover:text-white hover:scale-105 hover:border-primary hover:border-2' onClick={handleStartUsing}>
                        <h2 className="text-md font-bold">How to start using PrivseaVPN?</h2>
                        <FaArrowRight className="text-5xl text-primary absolute top-6 right-5" size={18} />
                    </div>
                    <div className='bg-base-200 w-96 h-fit p-5 relative cursor-pointer hover:bg-base-300 hover:text-white hover:scale-105 hover:border-primary hover:border-2' onClick={handleConnectIssues}>
                        <h2 className="text-md font-bold">How can I fix connectivity issues?</h2>
                        <FaArrowRight className="text-5xl text-primary absolute top-6 right-5" size={18} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support