import React from 'react';
import Servers from '../components/Servers';
import Uptime from '../components/Uptime';

const Products = () => {
    return (
        <div>
            <Servers />
            <Uptime />
        </div>
    );
};

export default Products;
